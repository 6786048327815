<template>
  <div class="finacepreview">
    <NavBar :list="navList" name="财务概览"></NavBar>
    <div class="public-box">
    <div class="finacepreviewNav">
      <div class="list">
        <div class="title">
          <img src="../../../assets/img/weiwancheng.png" alt="">
          <span>未完成退款事件</span>
        </div>
        <div class="num">
          {{datails && datails.detail.refund_total || 0}}
        </div>
      </div>
      <div class="list">
        <div class="title">
          <img src="../../../assets/img/tuilkuan.png" alt="">
          <span>今日申请退款事件</span>
        </div>
        <div class="num">
          {{datails && datails.detail.today_apply || 0}}
        </div>
      </div>
      <div class="list">
        <div class="title">
          <img src="../../../assets/img/shoukuan.png" alt="">
          <span>昨日总收款</span>
        </div>
        <div class="num">
          {{datails && datails.detail.ter_day_total || 0}} <span>笔</span>
        </div>
      </div>
      <div class="list">
        <div class="title">
          <img src="../../../assets/img/daiduizhang.png" alt="">
          <span>未对账</span>
        </div>
        <div class="num">
          {{datails && datails.detail.not_extract || 0}}<span>笔</span>
        </div>
      </div>
    </div>
    <div class="echarsBox">
      <div id="accountBill" ></div>
    </div>
  </div>
  </div>
</template>
<script>
import * as echarts from 'echarts';

export default {
  name: 'finacepreview',
  data() {
    return {
      navList: [{ name: '财务概览' }],
      myChart: null,
      datails: null
    };
  },
  created() {
    this.getStatistics();
  },
  methods: {
    getStatistics() {
      this.$request.HttpGet('/bill_offline/statistics').then(res => {
        if (res.data) {
          this.datails = res.data;
          let date = [];
          let extract_total = [];
          let not_extract_total = [];
          let total_extract_price = [];
          for (let i in res.data.offline_info) {
            date.push(res.data.offline_info[i].day);
            extract_total.push(res.data.offline_info[i].total_pay_price);
            not_extract_total.push(res.data.offline_info[i].diff_extract_price);
            total_extract_price.push(res.data.offline_info[i].total_extract_price)
          }
          this.myChart = echarts.init(document.getElementById('accountBill'));
          let option = {
            title: {
             text: '账单核对数据图',
             left: '2%'
            },
            legend: {
              data: ['收款金额', '核对金额', '差异金额'],
              right: '2%'
            },
            tooltip: {
              trigger: 'axis'
            },
            grid: {
             left: '3%',
             right: '4%',
             bottom: '3%',
             containLabel: true
           },
            xAxis: {
              data: date
            },
            yAxis: {},
            series: [
              {
                name: '收款金额',
                smooth: true,
                type: 'line',
                data: extract_total,
                itemStyle: {
                  normal: {
                    label: {
                      show: true
                    },
                    color: '#297AFE'
                  }
                }
              },
              {
                name: '核对金额',
                smooth: true,
                type: 'line',
                data: not_extract_total,
                itemStyle: {
                  normal: {
                    label: {
                      show: true
                    },
                    color: '#02CE80'
                  }
                }
              },
              {
                name: '差异金额',
                smooth: true,
                type: 'line',
                data: total_extract_price,
                itemStyle: {
                  normal: {
                    label: {
                      show: true
                    },
                    color: '#F2884A'
                  }
                }
              }
            ]
          };
          this.myChart.setOption(option);
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
  .finacepreview {
    .finacepreviewNav {
      display: flex;
      .list {
        flex: 1;
        height: 98px;
        background: #FFFFFF;
        border: 1px solid #DEDEDE;
        margin-right: 22px;
        box-sizing: border-box;
        padding: 20px 40px;
        &:last-child {
          margin-right: 0px;
        }
        .title {
          display: flex;
          align-items: center;
          font-size: 12px;
          img {
            width: 14px;
            height: 14px;
            margin-right: 8px;
          }
        }
        .num {
          text-align: center;
          margin-top: 5px;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          span {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            margin-left: 5px;
          }
        }
      }
    }
    .echarsBox {
      width: 100%;
      height: 569px;
      border: 1px solid #DEDEDE;
      margin-top: 20px;
      padding-top: 20px;
      #accountBill {
        width: 100%;
        height: 569px;
      }
    }
  }
</style>
